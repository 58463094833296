import { template as template_a382212e232a46d3999e9c25059eba26 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a382212e232a46d3999e9c25059eba26(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
