import { template as template_411523c8ffbf40b7905f92f8aea99651 } from "@ember/template-compiler";
const SidebarSectionMessage = template_411523c8ffbf40b7905f92f8aea99651(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
