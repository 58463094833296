import { template as template_b5e2d0a48c454c1fbbe23daca1c18be7 } from "@ember/template-compiler";
const WelcomeHeader = template_b5e2d0a48c454c1fbbe23daca1c18be7(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
